<!--
* 创建人：罗兴
* 日 期：
* 描 述：辅导员考核规则
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">辅导员考核规则</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button type="primary" size="mini" @click="toadd">添加</el-button>
      <el-button type="primary" size="mini" @click="toedit">修改</el-button>
      <el-button type="primary" size="mini" @click="todelete">删除</el-button>
      <el-button type="primary" size="mini" @click="search">查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div v-html="'规则名称'" class="sm_search_title"></div>
          <el-input size="mini" v-model="keyword"></el-input>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 240"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="gzmc" label="规则名称"> </el-table-column>
        <el-table-column prop="fs" label="满分"> </el-table-column>
        <el-table-column prop="xh" label="序号"> </el-table-column>
        <el-table-column prop="zt" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.zt == 1" type="success" effect="dark">
              开启
            </el-tag>
            <el-tag v-if="scope.row.zt == 0" type="danger" effect="dark">
              关闭
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="辅导员考核规则"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 350px" class="myscrollbar">
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规则名称" label-width="150px" prop="GZMC">
                <el-input v-model="forminfo.GZMC"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="分数" label-width="150px" prop="FS">
                <el-input v-model="forminfo.FS"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="序号" label-width="150px" prop="XH">
                <el-input v-model="forminfo.XH" type="number"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" label-width="150px" prop="ZT">
                <el-select
                  v-model="forminfo.ZT"
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <el-option label="开启" :value="1"> </el-option>
                  <el-option label="关闭" :value="0"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="规则说明" label-width="150px" prop="KHYD">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              placeholder="请输入内容"
              v-model="forminfo.KHYD"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getGZPageList, saveGZ, getGZInfo, deleteGZ } from '../../api/fdykh'
export default {
  data() {
    return {
      dialogFormVisible: false,
      keyValue: '',
      keyword: '',
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      formdata: {},
      operation: 1,
      forminfo: {
        ID: '',
        GZMC: '',
        FS: '',
        XH: '',
        ZT: 1,
        KHYD: '',
      },
      // 表单验证规则对象
      forminfoRules: {
        GZMC: [{ required: true, message: '请填写规则名称', trigger: 'blur' }],
        FS: [
          {
            required: true,
            message: '请填写分数',
            trigger: 'blur',
          },
        ],
        XH: [{ required: true, message: '请填写序号', trigger: 'blur' }],
        ZT: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        KHYD: [{ required: true, message: '请填写规则说明', trigger: 'blur' }],
      },
      currdata: {},
    }
  },
  created() {
    this.getDataPageList()
  },
  methods: {
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getGZPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    todelete() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteGZ({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
        this.bm = ''
        this.zy = ''
      })
      this.$refs.formRef.resetFields()
    },
    toadd() {
      this.dialogFormVisible = true
    },
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.forminfo.ID = this.keyValue
          saveGZ(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.getDataPageList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 显示编辑弹出框
    toedit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        getGZInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.GZMC = res.data.gzmc
            this.forminfo.FS = res.data.fs
            this.forminfo.XH = res.data.xh
            this.forminfo.ZT = res.data.zt
            this.forminfo.KHYD = res.data.khyd
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
